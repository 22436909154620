/* eslint-disable no-restricted-globals */
import Ws from '@adonisjs/websocket-client';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  toastSuccess,
  toastError,
  returnBaseUrlWs,
  openUrl,
} from '../../helpers/Utils';
import api from '../../services/api';
import {
  setCreditCardData,
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  RECONECT_USER,
  LOGIN_QRCODE,
} from '../actions';
import {
  setChannelCallWs,
  callExternal,
  openModalCallExternal,
} from '../call/actions';
import { setChannelListenerWs } from '../listener/actions';
import {
  clearUser,
  loginUser,
  loginUserSuccess,
  registerUserError,
} from './actions';

function reloadWs() {
  if (sessionStorage.getItem('token')) {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
}

function connectWs(token) {
  const ws = Ws(returnBaseUrlWs());
  ws.withJwtToken(token).connect();

  // executa apos conseguir conectar ao servidor
  ws.on('open', () => {
    // alert('conectou ao socket');
    /*
     * o ideal é colocar os subscribe (channels ws) dentro dessa
     * funcao, pois quando socket reinicia essa funcao é chamada
     * e automaticamente reconecta ao channels do ws.
     *
     * o problema de conectar os channels aqui dentro, é que preciso
     * dessa intancia gerada ao conectar ao channel em outros locais
     * da aplicação, tentei setar ela no redux usando yeld (put), mas
     * nao seta o valor no redux, verificar depois isso.
     *
     * em paleativo estou atualizando toda aplicação para reconectar
     * aos channels do ws.
     */
  });

  // executa caso não tenha conseguido conectar ao servidor
  ws.on('error', (error) => {
    console.error('Connect websocket error: ', error);
    reloadWs();
  });

  // executa apos fazer o logout
  ws.on('close', () => {
    reloadWs();
  });

  window.addEventListener('online', () => {
    toastSuccess({
      message: 'Você está online novamente!',
    });
  });

  window.addEventListener('offline', () => {
    const offlineInterval = setInterval(() => {
      if (!navigator.onLine) {
        toastError({
          message: 'O usuário perdeu a conexão com a Internet!',
        });
      } else {
        clearInterval(offlineInterval);
      }
    }, 5000);
  });

  return ws;
}

function channelCallWs(ws) {
  const channelCall = ws.subscribe('call');

  channelCall.on('error', (error) => {
    console.error('Channel ws connection error "channelCall": ', error);
    reloadWs();
  });

  channelCall.on('messageError', (error) => {
    toastError({ message: error, time: 15000 });
  });

  return channelCall;
}

function channelListenerWs(ws) {
  const channelListener = ws.subscribe('listener');

  channelListener.on('error', (error) => {
    console.error('Channel ws connection error "channelListener": ', error);
    reloadWs();
  });

  return channelListener;
}

function* loginQrCode({ payload }) {
  const { history } = payload;

  sessionStorage.setItem('token', payload.user.token);
  const userData = JSON.stringify(payload.user.user);
  sessionStorage.setItem('user', userData);

  const ws = connectWs(payload.user.token);
  const channelCall = channelCallWs(ws);
  yield put(setChannelCallWs(channelCall));

  const hasActiveCall = yield call(api.get, '/call');

  if (hasActiveCall.data) {
    yield put(callExternal(hasActiveCall.data));
    yield put(openModalCallExternal(true));
  }

  yield put(loginUserSuccess({ user: payload.user.user, ws }));
  history.push('/');
}

function* login({ payload }) {
  const { username, password } = payload.user;
  const { history } = payload;

  try {
    const userNameLowerCase = username.toLowerCase();

    const { data } = yield call(api.post, '/login', {
      username: userNameLowerCase,
      password,
    });

    if (data.user) {
      const ws = connectWs(data.token);
      const channelCall = channelCallWs(ws);
      yield put(setChannelCallWs(channelCall));

      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('loginDate', data.user.loginDate);

      const user = JSON.stringify(data.user);
      sessionStorage.setItem('user', user);
      yield put(loginUserSuccess({ user: data.user, ws }));

      if (data.user.creditCard) {
        const creditCard = JSON.stringify(data.user.creditCard);
        sessionStorage.setItem('creditCard', creditCard);
        yield put(setCreditCardData(data.user.creditCard));
      }

      if (data.user.user_type !== 'Admin') {
        const hasActiveCall = yield call(api.get, '/call');

        if (hasActiveCall.data) {
          yield put(callExternal(hasActiveCall.data));
          yield put(openModalCallExternal(true));

          openUrl(
            data.user,
            hasActiveCall.data.urlCallExternal,
            hasActiveCall.data.twilioRoon
          );
        }
      } else {
        const channelListener = channelListenerWs(ws);
        yield put(setChannelListenerWs(channelListener));
      }

      history.push('/');
    }
  } catch (error) {
    if (!error.response) {
      toastError({
        message:
          'Não foi possível realizar o login, tente novamente mais tarde.',
      });
      yield put(registerUserError());
      return;
    }
    if (error.response.data.customMessage) {
      toastError({ message: error.response.data.customMessage });
      yield put(registerUserError());
      return;
    }
    if (error.response.data.error.name === 'UserNotFoundException') {
      toastError({ message: 'Usuário não cadastrado.' });
      yield put(registerUserError());
      return;
    }
    if (error.response.data.error.name === 'PasswordMisMatchException') {
      toastError({ message: 'Senha incorreta.' });
    }
    yield put(registerUserError());
  }
}

function* signup({ payload }) {
  try {
    const { data } = yield call(api.post, '/signup', payload.user);
    if (data) {
      yield put(loginUser(payload.user, payload.history));
    }
  } catch (error) {
    if (!error.response) {
      yield put(registerUserError());
      return;
    }
    if (error.response.data.customMessage) {
      let message = '';
      const errors = error.response.data.customMessage;
      Object.keys(errors).forEach((item) => {
        if (errors[item].properties.message === 'document já cadastrado.')
          message += ' CPF já cadastrado.';
        else if (errors[item].properties.message === 'username já cadastrado.')
          message += ' E-mail já cadastrado.';
        else message += ` ${errors[item].properties.message}`;
      });
      toastError({ message });
      yield put(registerUserError());
      return;
    }
    toastError({ message: 'Ocorreu um erro ao cadastrar o usuário.' });
    yield put(registerUserError());
  }
}

function* reconectUser() {
  sessionStorage.removeItem('blockEventBeforeLeave');
  sessionStorage.removeItem('blockEventReceiveChat');

  const user = JSON.parse(sessionStorage.getItem('user'));
  const token = sessionStorage.getItem('token');
  const ws = connectWs(token);
  const channelCall = channelCallWs(ws);

  yield put(setChannelCallWs(channelCall));
  yield put(loginUserSuccess({ user, ws }));

  const { data } = yield call(api.get, '/user');

  yield put(loginUserSuccess({ user: data.user, ws }));

  if (data.user.creditCard) yield put(setCreditCardData(data.user.creditCard));

  if (data.user.user_type !== 'Admin') {
    const hasActiveCall = yield call(api.get, '/call');

    if (hasActiveCall.data) {
      yield put(callExternal(hasActiveCall.data));
      yield put(openModalCallExternal(true));
    }
  } else {
    const channelListener = channelListenerWs(ws);
    yield put(setChannelListenerWs(channelListener));
  }
}

function* logout({ payload }) {
  const { history, ws } = payload;

  // desabilitado nova fila
  // const user = JSON.parse(sessionStorage.getItem('user'));

  // if (user.user_type === 'Interpreter') {
  //   await api.post('remove-user-from-service-queue', {
  //     user,
  //   });
  // }

  if (ws) {
    ws.close();
  }

  sessionStorage.removeItem('user');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('blockEventBeforeLeave');
  sessionStorage.removeItem('blockEventReceiveChat');

  yield put(clearUser());
  history.push('/');
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, signup);
}

export function* watchReconectUser() {
  yield takeEvery(RECONECT_USER, reconectUser);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLoginQrCode() {
  yield takeEvery(LOGIN_QRCODE, loginQrCode);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginQrCode),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchReconectUser),
  ]);
}
